import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import { GlobalLayout, WorkLayout } from 'components/Layout';
import { Banner, Images, Header } from 'components/Works';

function Chatforma() {
  return (
    <GlobalLayout>
      <WorkLayout>
        <Banner
          render={({ onLoad, onStartLoad }) => (
            <StaticImage
              placeholder='none'
              onStartLoad={onStartLoad}
              onLoad={onLoad}
              alt='Desktop main page'
              src='../../images/chatforma.png'
            />
          )}
        />

        <Header title='Chatforma' />

        <p>
          As messengers have become one of the most used apps both on
          smartphones and desktop devices, chatbots have also gained popularity,
          as they allow people to do various different things without leaving
          their messenger app. Chatbots can provide many services, such as
          customer support, online shopping and marketing. However, creating a
          chatbot requires coding experience or hiring a specialist. Chatforma
          solves that issue, giving people an easy and functional chatbot
          constructor, that supports many platforms such as Telegram, Facebook
          Messenger, Viber and VK.
        </p>
        <h2>The goal</h2>
        <p>
          Initially the client already had a working version of the platform
          with real users. My goal was to develop new features and fix some of
          the bugs the current platform had. However, due to poor architecture
          creating new features was financially unprofitable because of the
          issues the current codebase had. I estimated that in about 2-3 months
          the project could be rewritten from scratch with all the old and new
          features, but with the right architecture the further development and
          maintanance would become much faster and easier. The client agreed to
          our proposal and my team started working on the new version of the
          platform.
        </p>
        <h2>My role</h2>
        <p>
          At the start of the project I was invited as a React.js and Node.js
          developer. My responsibility was to audit the current codebase and
          offer improvements, developing new features and fixing bugs alongside.
          After the client agreed to rewrite the project from scratch, I took
          the role of the team lead and led the development of Chatforma v2. I
          was managing the design of the platform’s UI, creating prototypes and
          working on the UX. After the design of the UI was made, I’ve designed
          the architecture of the backend and frontend of the project as a
          fullstack javascript developer and also managed 2 other developers and
          thier work.
        </p>

        <Images description='Message sending'>
          <StaticImage
            alt='Chatforma message sending'
            src='../../images/chatforma/screen-1.png'
          />
        </Images>

        <h2>Technologies</h2>
        <p>
          Originally the project was created with a cloud-backend solution based
          on Node.js, that was later deprecated and taken down, which forced the
          developers to run the server on their own. MongoDB was used as a
          database and React.js SPA for the frontend.
        </p>
        <p>
          Сloud backend was replaced with a Node.js API using Adonis.js
          framework, which provides all of the features needed to create a
          powerful Restful API, has excellent documentation and is easily
          extendable. The MongoDB was also replaced with PostgreSQL as it was
          more important to have a stable and reliable schema.
        </p>
        <p>
          The fronted was written with a battle-tested React.js stack and
          consisted mainly of complex forms and fields.
        </p>
        <p>The final stack for the project was:</p>
        <ul>
          <li>React.js + Redux + styled-components for frontend</li>
          <li>
            Node.js with Adonis.js framework for both dashboard API and
            chatbot’s backend with unit tests
          </li>
          <li>Redis for message distribution queue</li>
          <li>PostgreSQL as a database</li>
        </ul>
        <Images description='Form data collection'>
          <StaticImage
            alt='Chatforma form data collection'
            src='../../images/chatforma/screen-2.png'
          />
        </Images>
        <h2>Result</h2>
        <p>
          The project was successfully rewritten, introducing new features and
          fixing countless amount of bugs. The UX was drastically improved
          alongside the performance of the connected chatbots. All the chatbots
          linked to the first version of the platform were fully migrated, so
          that users won’t loose anything during the transition to the new
          version. And most importanly, new architecture allowed to release new
          features multiple times faster, making clients happy and allowing the
          business to grow.
        </p>
      </WorkLayout>
    </GlobalLayout>
  );
}

export default Chatforma;
